/**
 * 导入数据对应表
 */
export const USER_RELATIONS = {
  姓名: 'name',
  身份证号: 'id_card',
  系部: 'college_name',
  专业: 'domain_name',
  层次: 'level',
  年级: 'grade',
  班级: 'class_name',
  班主任: 'teacher',
  入学年份: 'year',
  缴费金额: 'amt',
  '缴费渠道（光大银行、微信商户）': 'pay_type',
  缴费开始时间: 'start_time',
  缴费结束时间: 'end_time'
}

/**
 * 解析 excel 导入的时间格式
 */
export const formatDate = (numb) => {
  const time = new Date((numb - 1) * 24 * 3600000 + 1)
  time.setYear(time.getFullYear() - 70)
  const year = time.getFullYear() + ''
  const month = time.getMonth() + 1 + ''
  const date = time.getDate() - 1 + ''
  return (
    year +
    '-' +
    (month < 10 ? '0' + month : month) +
    '-' +
    (date < 10 ? '0' + date : date)
  )
}
